body,
#root,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
a,
.td-name,
td,
button,
input,
select,
textarea {
  color: rgba(27, 28, 28, 1);
  font-family: sans-serif !important;
  font-weight: 400;
}
@media (min-width: 1200px) {
  .container {
    max-width: 960px !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 80%;
  }
}
@media (min-width: 768px) {
  .social-icons.mini-nav {
    width: 30px !important;
    height: 30px !important;
    font-size: 16px !important;
  }
  .navbar .logo img.mini-nav {
    width: 75% !important;
  }
  .navbar .m-head.mini-nav {
    top: 50% !important;
  }
  .main-head {
    width: 100%;
    margin-top: 40px;
  }
}
@media (max-width: 600px) {
  .navbar {
    padding: 0 !important;
  }
  .slider-text .text {
    font-size: 25px !important;
  }
  .slider-text {
    padding: 6em 0 4em !important;
  }
  .ul-6 li,
  .ul-4 li,
  .ul-6-m-0 li {
    width: 100% !important;
  }
  .services .col {
    min-width: 30%;
  }
}
@media (max-width: 768px) {
  .navbar .logo img,
  .navbar .logo img.mini-nav {
    width: 100% !important;
  }
  .social-icons,
  .social-icons.mini-nav {
    font-size: 14px !important;
    width: 27px !important;
    height: 27px !important;
  }
  .navbar .m-head.mini-nav {
    top: 40% !important;
  }
  .ln-btn {
    height: 58px !important;
    font-size: 17px !important;
  }
  .menu-head {
    padding: 0;
  }
  .menu-head .m-head {
    text-align: right !important;
  }
  .navbar .m-head {
    top: 2% !important;
  }
  .main-head {
    width: 100%;
    margin-top: 20px;
  }
  .slider-text {
    margin-top: 135px !important;
  }
  .head1 {
    font-size: 30px !important;
  }
  .head2 {
    font-size: 22px !important;
    margin-bottom: 0 !important;
  }
  .lg-youtube {
    height: auto !important;
  }
  .panel-wrapper {
    padding: 10px 15px !important;
  }
  .footer-head {
    font-size: 17px !important;
  }
  .nav-height {
    margin-top: 180px !important;
  }
  .terms-conditions {
    font-size: 12px !important;
    width: 100% !important;
  }
  p {
    font-size: 16px !important;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px !important;
    padding: 8px 12px !important;
  }
  .fixed-icon-list {
    top: 24% !important;
  }
  .fixed-icon .animated-icon {
    width: 40px !important;
    height: 40px !important;
    line-height: 43px !important;
  }
}
.navbar-toggler {
  position: absolute;
  top: -41px;
  height: 32px;
  right: 20px;
}
.navbar-collapse {
  overflow-y: auto;
}
a {
  color: rgba(7, 55, 102, 1) !important;
}
img {
  width: 100%;
}
p {
  font-size: 17px;
  line-height: 1.5;
}
.p-20 {
  padding-left: 20%;
}
.pt-0 {
  padding-top: 0;
}
.pl-0 {
  padding-left: 0;
}
.brd-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.ul-6 li {
  /* width: 50%; */
  width: 40%;
  margin: 0 5%;
  float: left;
}
.ul-6-m-0 li {
  width: 50%;
  margin: 0;
  float: left;
}
.ul-4 li {
  /* width: 50%; */
  width: 33.33%;
  float: left;
}
.form-control {
  border-radius: 0;
}
.cl-brd .form-control {
  border: 4px solid #fff;
  outline: 1px solid #fff;
  transition: 0.1s;
}
.cl-brd .form-control:focus {
  border: 4px solid #04294d !important;
  outline: 1px solid #fff !important;
  border-radius: 6px !important;
}
.shadow-bg {
  background-color: rgba(246, 246, 246, 1) !important;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}
.ln-btn {
  width: auto;
  height: 62px;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 200px !important;
  margin-top: 28px !important;
  margin-bottom: 10px !important;
  padding-right: 0 !important;
  min-width: 25px !important;
  text-align: center !important;
  display: block !important;
  font-size: 18px;
  background-color: rgba(7, 55, 102, 1) !important;
  border-radius: 30px;
}
.ln-btn .btn-txt {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  white-space: normal;
  margin-top: 8px;
  padding: 10px 7px;
  max-width: 98%;
  font-weight: 600;
  font-family: sans-serif;
}
.bld {
  font-weight: bold;
}
.innerList {
  font-weight: bold;
  font-size: 17px;
  margin: 15px 0;
  display: inline-block;
}
.panel-wrapper {
  padding: 40px 30px;
}
.m-head {
  font-size: 30px;
  color: rgba(0, 21, 41, 1) !important;
  font-weight: bold;
}
.head1 {
  font-size: 45px;
  text-transform: uppercase;
  color: rgba(0, 21, 41, 1) !important;
  text-align: center;
  margin: 8px 0;
  padding: 2px 0;
  display: inline-block;
  font-family: sans-serif;
  font-weight: 700;
}
.head1:after,
.footer-head:after {
  border-bottom: 1px solid rgba(7, 55, 102, 1);
  display: block;
  content: " ";
  width: 35%;
  margin: 10px auto;
}
.head2 {
  font-size: 30px;
  width: calc(100% - 0px) !important;
  position: relative !important;
  padding: 2px 0 !important;
  max-width: 100% !important;
  min-width: 25px !important;
  text-align: center;
  margin: 8px auto;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
}
.footer-head {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff !important;
  text-align: center;
  margin: 8px 0;
  padding: 2px 0;
  display: inline-block;
  font-family: sans-serif;
  font-weight: 700;
}
.footer-head:after {
  border-bottom: 1px solid #fff !important;
}
.error {
  color: #ca0202;
  font-size: 13px;
}
.small {
  font-size: 13px;
}

navbar-toggle {
  outline: 0 !important;
}
/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert,
.carousel-control.right,
.carousel-control.left {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.offline-doc .navbar.navbar-transparent {
  padding-top: 25px;
  border-bottom: none;
}
.offline-doc .navbar.navbar-transparent .navbar-minimize {
  display: none;
}
.offline-doc .navbar.navbar-transparent .navbar-brand,
.offline-doc .navbar.navbar-transparent .collapse .navbar-nav .nav-link {
  color: #ffffff !important;
}
.navbar {
  border: 0;
  font-size: 14px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  padding: 0;
  background: #ffffff;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}
.navbar .navbar-brand {
  font-weight: 600;
  margin: 5px 0px;
  padding: 20px 15px;
  font-size: 14px;
  color: #66615b;
  text-transform: uppercase;
}
.navbar .navbar-toggler:focus {
  outline: none;
}
.navbar .form-control-feedback {
  padding-left: 0px;
}
.navbar:not([class*="bg"]) .navbar-toggler .navbar-toggler-bar {
  background: #66615b !important;
}
.navbar .navbar-nav .nav-item .nav-link {
  line-height: 1.6;
  margin: 15px 3px;
  padding: 10px 15px;
  opacity: 0.8;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #66615b;
}
.navbar .navbar-nav .nav-item .nav-link p {
  margin: 0px 0px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5em;
  padding: 15px 0;
}
.navbar .navbar-nav .nav-item .nav-link.btn {
  margin: 15px 3px;
  padding: 9px;
}
.navbar .navbar-nav .nav-item .nav-link [class^="fa"] {
  font-size: 17px;
  position: relative;
  right: 5px;
}
.navbar .navbar-nav .dropdown-menu {
  border-radius: 12px;
  margin-top: 1px;
}
.navbar .navbar-nav .nav-item .btn i {
  color: #ffffff;
}
.navbar .navbar-collapse .nav-item .nav-link p {
  display: inline;
}
.navbar .navbar-collapse .nav-item .dropdown-item i {
  margin: 0 10px;
  margin: 0 10px 0px 5px;
  font-size: 18px;
  position: relative;
  top: 3px;
}
.navbar .navbar-collapse.show .navbar-nav .nav-item {
  padding-right: 10px;
}
.navbar .navbar-collapse:after {
  background-color: #fffcf5;
}
.navbar #navbarSupportedContent .nav-item {
  position: relative;
}
.navbar .notification-bubble {
  padding: 0.4em 0.6em;
  position: absolute;
  top: 10px;
  right: -2px;
}
.navbar .btn {
  margin: 14px 3px;
  font-size: 12px;
}
.navbar .btn i {
  font-size: 14px;
  position: relative;
  top: 2px;
}
.navbar .btn-simple {
  font-size: 16px;
}
.navbar .caret {
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
}
.navbar.navbar-transparent {
  background: transparent !important;
  padding-top: 25px;
  box-shadow: none;
}
.navbar.navbar-transparent .navbar-brand {
  color: #ffffff;
}
.navbar.navbar-transparent .navbar-nav .nav-item .nav-link {
  color: #ffffff;
}
.navbar.navbar-transparent .navbar-toggler .navbar-toggler-bar {
  background: #ffffff !important;
}
.navbar .logo-container {
  margin-top: 5px;
}
.navbar .logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}
.navbar .logo-container .logo img {
  width: 100%;
}
.navbar .logo-container .brand {
  font-size: 18px;
  color: #ffffff;
  line-height: 20px;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
  width: 75px;
  height: 50px;
}
.navbar .navbar-toggler .navbar-toggler-bar + .navbar-toggler-bar,
.navbar .navbar-toggler .navbar-toggler-icon + .navbar-toggler-icon {
  margin-top: 4px;
}
.navbar .navbar-toggler .navbar-toggler-bar {
  background: #ffffff !important;
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  margin: 0 auto;
}

.navbar-transparent .navbar-brand,
[class*="bg"] .navbar-brand {
  color: #ffffff;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.navbar-transparent .navbar-brand:focus,
.navbar-transparent .navbar-brand:hover,
[class*="bg"] .navbar-brand:focus,
[class*="bg"] .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #ffffff;
}

.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn),
[class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #ffffff;
  border-color: #ffffff;
}

.navbar-transparent .navbar-nav .active .nav-link .active .nav-link:hover,
.navbar-transparent .navbar-nav .active .nav-link:focus,
.navbar-transparent .navbar-nav .nav-item .nav-link:hover,
.navbar-transparent .navbar-nav .nav-item .nav-link:focus,
[class*="bg"] .navbar-nav .active .nav-link .active .nav-link:hover,
[class*="bg"] .navbar-nav .active .nav-link:focus,
[class*="bg"] .navbar-nav .nav-item .nav-link:hover,
[class*="bg"] .navbar-nav .nav-item .nav-link:focus {
  background-color: transparent;
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .navbar-nav .nav .nav-item a.btn:hover,
[class*="bg"] .navbar-nav .nav .nav-item a.btn:hover {
  background-color: transparent;
}

.navbar-transparent .navbar-nav .dropdown .nav-link .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:hover .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:focus .caret,
[class*="bg"] .navbar-nav .dropdown .nav-link .caret,
[class*="bg"] .navbar-nav .dropdown .nav-link:hover .caret,
[class*="bg"] .navbar-nav .dropdown .nav-link:focus .caret {
  border-bottom-color: #ffffff;
  border-top-color: #ffffff;
}

.navbar-transparent .navbar-nav .open .nav-link,
.navbar-transparent .navbar-nav .open .nav-link:hover,
.navbar-transparent .navbar-nav .open .nav-link:focus,
[class*="bg"] .navbar-nav .open .nav-link,
[class*="bg"] .navbar-nav .open .nav-link:hover,
[class*="bg"] .navbar-nav .open .nav-link:focus {
  background-color: transparent;
  color: #66615b;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .btn-default.btn-fill,
[class*="bg"] .btn-default.btn-fill {
  color: #9a9a9a;
  background-color: #ffffff;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default,
[class*="bg"] .btn-default.btn-fill:hover,
[class*="bg"] .btn-default.btn-fill:focus,
[class*="bg"] .btn-default.btn-fill:active,
[class*="bg"] .btn-default.btn-fill.active,
[class*="bg"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}

.bd-docs .navigation-example .navbar.navbar-transparent {
  padding-top: 0;
  padding: 20px 0;
  margin-top: 20px;
}

.navbar {
  padding: 0 40px;
  z-index: 1;
}
.navbar .m-head {
  top: 12%;
  transition: 0.5s;
}
.navbar .logo img {
  width: 100%;
  transition: 0.5s;
}
.vt-justify {
  margin: 0;
  position: relative;
  font-family: sans-serif;
}
.nav-height {
  margin-top: 320px;
}
.navbar .navbar-nav .nav-item .nav-link {
  white-space: nowrap;
  color: rgba(7, 55, 102, 1) !important;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 12px;
  margin: 5px;
  opacity: 1 !important;
}
.navbar .navbar-nav .nav-item .nav-link.active,
.navbar .navbar-nav .nav-item .nav-link:hover {
  border-color: rgba(79, 107, 134, 1) !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 6px;
}
.navbar-translate {
  margin-bottom: 15px;
}
.menu {
  justify-content: center;
}
.head-contact {
  font-size: 21px;
  top: 0;
  transition: 0.5s;
}
.head-contact.mini-nav {
  top: 50% !important;
}
.fb {
  background-color: #3b5998;
}
.ln {
  background-color: #0e76a8;
}
.yt {
  background-color: #ff0000;
}
.social-icons {
  color: #fff;
  font-size: 19px;
  width: 36px;
  height: 36px;
  margin: 3px;
  border-radius: 9px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.social-icons i {
  color: #fff !important;
}
.logo {
  padding: 0 !important;
  margin: 0 !important;
}
.logo img {
  width: 100%;
}
/* slider */
.bg-slider {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
.slider-text {
  padding: 33em 0 10em;
  color: #fff;
  margin-top: 180px;
  text-align: center;
}
.slider-text .text {
  font-size: 50px;
  text-shadow: 2px 2px 4px #000 !important;
  font-weight: 600;
  font-family: sans-serif;
  color: #fff;
}
.lg-youtube {
  width: 100%;
  height: 419px;
  margin: 15px 0 20px;
}
.lg-youtube iframe {
  width: 100%;
  height: 100%;
}
.cl-bg {
  background-color: rgba(4, 41, 77, 1) !important;
  color: #fff;
}
.dark-bg {
  background-color: rgba(0, 21, 41, 1) !important;
}
.services .col {
  padding: 0 5px;
}
.services img {
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-color: rgba(204, 204, 204, 1) !important;
  border-width: 1px !important;
  border-style: solid !important;
  max-width: 165px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;
}
.services a {
  color: #fff !important;
  font-weight: bold;
  padding: 0;
  margin: 10px 0;
}
.footer,
.footer p,
.footer a {
  color: #fff !important;
  text-align: center;
}
.footer .dark-bg {
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: rgba(75, 75, 75, 1) !important;
}
.footer .cl-bg {
  border-top-style: solid !important;
  border-top-color: rgba(236, 174, 61, 1) !important;
  border-top-width: 2px !important;
}
.terms-conditions {
  font-size: 12px;
  width: 65%;
  margin: auto;
}
.fixed-icon-list {
  position: fixed;
  right: 0;
  top: 19%;
  z-index: 2;
}
.fixed-icon {
  margin: 0 12px 12px 0;
  clear: both;
  position: relative;
  text-align: center;
  display: flex;
  z-index: 2;
}
.fixed-icon .animated-icon {
  width: 50px;
  height: 50px;
  line-height: 47px;
  font-size: 19px;
  border-radius: 50%;
  float: left;
  color: #fff;
  transition: 0.3s;
  background-color: #4b94e6;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  cursor: pointer;
}
.fixed-icon .animated-icon:hover {
  transform: scale(1.15);
}
.animated-icon + .animated-text {
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  position: absolute;
  right: 0;
  line-height: 2.3;
  white-space: nowrap;
  padding: 0 25px;
  top: 10px;
  border-radius: 50px;
  transition: 0.3s;
  opacity: 0;
  z-index: 1;
}
.animated-icon:hover + .animated-text {
  right: 65px !important;
  opacity: 1 !important;
}
.modal {
}
.modal-backdrop.show {
  opacity: 0.7;
}
.modal.show .modal-dialog {
  /* max-width: 80% !important;
    width: 80%!important; */
  padding-top: 0;
}
.modal-content {
  border-radius: 0;
}
.modal-header {
  background-color: #4675d9 !important;
  border-radius: 0;
}
.modal-title {
  width: 100%;
  color: #fff;
}
.close,
.close:hover,
.close:focus {
  color: #fff !important;
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none !important;
}
.modal-logo {
  width: 60px;
  margin-bottom: 8px;
}
.modal-title p {
  color: #fff;
  line-height: 1.2;
  margin: 0;
}
.modal-body {
  height: calc(100vh - 3.5em - 101px);
  min-height: 400px;
  overflow: auto;
  padding: 2rem 1rem 3rem;
}
.rv-parking-bg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: 50% 81.8182px;
}
.contact-img {
  width: 80%;
  border: 1px solid #ccc;
  margin-top: 15px;
}
.contact {
  padding: 25px 15px;
}
.contact .head {
  color: #fff;
  margin-bottom: 20px;
}
.wht-btn {
  border-radius: 50px;
  width: 290px;
  background: #fff;
  border: 1px solid;
  color: #04294d;
}
.maps {
  position: relative;
  height: 100%;
}
.maps iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.toggleButton,
.toggleButton:hover,
.toggleButton:focus {
  background-color: rgba(142, 142, 142, 0.23) !important;
  border: none !important;
  color: #333 !important;
  outline: none !important;
}

@media (max-width: 768px) {
  .video-overlay-title {
    font-size: 1.5rem !important;
  }

  .reserve-button {
    font-size: 16px !important;
  }
}

@media (max-width: 480px) {
  .video-overlay-title {
    font-size: 1.25rem !important;
  }

  .reserve-button {
    font-size: 14px !important;
    padding: 8px 16px !important;
  }
}
